import React from 'react'

import { Imprint } from 'Components/landing'
import { Layout, SEO } from 'Common'

const ImprintPage = () => (
  <Layout>
    <SEO />
    <Imprint />
  </Layout>
)

export default ImprintPage
